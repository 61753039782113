import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080/api/v1/auth/";

const API_URL = "/api/v1/auth/";

const registerPartner = async (
  firstname,
  lastname,
  email,
  role,
  phoneNumber,
  password
) => {
  try {
    const userData = {
      firstname,
      lastname,
      email,
      role,
      phoneNumber,
      password,
    };

    const config = {
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
    };

    const response = await axios.post(
      API_URL + "register",
      JSON.stringify(userData),
      config
    );
    if (response.data) {
      localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

const registerCustomer = async (userData) => {
  try {
    const {
      firstname,
      lastname,
      email,
      username,
      role,
      acceptedTermsAndConditions,
      phoneNumber,
      password,
      referredBy,
    } = userData;


    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      API_URL + "register",
      JSON.stringify({
        firstname,
        lastname,
        email,
        username,
        role,
        acceptedTermsAndConditions,
        phoneNumber,
        password,
        referredBy,
      }),
      config
    );
    // ... rest of the code ...
  } catch (error) {
    throw error;
  }
};

const registerCourier1 = async (
  firstname,
  lastname,
  email,
  role,
  vehicleMake,
  vehicleModel,
  vehicleType,
  licensePlateNumber,
  phoneNumber,
  password,
  policeRecord,
  driverLicense
) => {
  try {
    const formData = new FormData();

    // Add the string data
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            firstname,
            lastname,
            email,
            role,
            vehicleMake,
            vehicleModel,
            vehicleType,
            licensePlateNumber,
            phoneNumber,
            password,
          }),
        ],
        { type: "application/json" }
      )
    );

    // Add the file data
    formData.append("policeRecord", policeRecord);
    formData.append("driversLicense", driverLicense);

    // Log the form data (excluding files)
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value instanceof Blob ? "File..." : value);
    }

    // Send the request
    const response = await axios.post(API_URL + "register/courier", formData);

    // ... rest of the code ...
  } catch (error) {
    throw error;
  }
};

const login = async (email, password) => {
  const response = await axios.post(API_URL + "authenticate", {
    email,
    password,
  });
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data)); // Store user data
    localStorage.setItem(
      "accessToken",
      JSON.stringify(response.data.accessToken)
    ); // Assuming accessToken is part of the response
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userRole");
};

const AuthService = {
  registerPartner,
  registerCustomer,
  registerCourier1,
  login,
  logout,
};

export default AuthService;
